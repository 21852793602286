import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from '~/components/header'
import NavbarFooter from '~/components/navbarFooter'
import NavbarFooterEn from '~/components/navbarFooterEn'

const Layout = ({ children, isEn = false, isAbsolute = true }) => {
  const data = useStaticQuery(graphql`
    query SiteNameQuery {
      strapiGlobal {
        siteName
        Socials {
          Email
          Facebook
          Instagram
        }
      }
    }
  `)

  return (
    <div className="bg-gray-50 relative font-body">
      <div className="min-h-screen">
        <Header
          siteName={data.strapiGlobal.siteName || `Strapi`}
          isEn={isEn}
          socials={data.strapiGlobal.Socials}
          isAbsolute={isAbsolute}
        />
        <main className="flex-1">{children}</main>
      </div>
      {isEn ? <NavbarFooterEn /> : <NavbarFooter />}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
