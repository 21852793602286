import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

const NavbarMain = () => {
  return (
    <StaticQuery
      query={query}
      render={({ allStrapiMenu }) => {
        const urls = allStrapiMenu.nodes[0].url
        return (
          <nav className="hidden tablet:block">
            <ul className="inline-block mx-auto">
              {urls.map(({ label, url }, id) => (
                <li className="float-left px-2 py-3 uppercase" key={id}>
                  <Link className="text-black no-underline" to={url}>
                    {label}
                  </Link>
                </li>
              ))}
              <li className="inline leading-8 text-xl tablet:text-tiny">
                <Link
                  target="_blank"
                  className="text-white px-1 my-3 tablet:my-0"
                  to="/"
                >
                  <img
                    style={{ top: '-1px' }}
                    className="relative inline px-2 py-3"
                    src="/france.png"
                    alt="french flag"
                  />
                </Link>
              </li>
            </ul>
          </nav>
        )
      }}
    />
  )
}

const query = graphql`
  query mainNavQueryEn {
    allStrapiMenu(filter: { locale: { eq: "en" } }) {
      nodes {
        url
      }
    }
  }
`

export default NavbarMain
