import React, { useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import DetectOutside from '../utils/DetectOutside'

const NavbarFooterEn = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <StaticQuery
      query={query}
      render={({ allStrapiNavigationDuFooter, allStrapiMenu }) => {
        const urls = allStrapiNavigationDuFooter.nodes[0].url
        const mainUrls = allStrapiMenu.nodes[0].url
        return (
          <DetectOutside callback={() => setMenuOpen(false)}>
            <nav
              className={`w-full z-10 fixed bottom-0 py-2 bg-gray-800 text-white tablet:static`}
              style={{ bottom: '0' }}
            >
              <ul
                className={`${
                  menuOpen ? 'block mb-4' : 'hidden'
                } text-center px-2 tablet:block tablet:mb-0`}
              >
                {mainUrls.map((el, index) => (
                  <li
                    className="inline leading-8 text-xl tablet:hidden"
                    key={index}
                  >
                    {el.url ? (
                      <Link
                        rel="noopener noreferrer"
                        target="_blank"
                        className="text-white px-1 my-3"
                        to={el.url}
                      >
                        {el.label}
                      </Link>
                    ) : (
                      <a
                        className="text-white px-1 my-3 tablet:my-0"
                        href={el.file[0].url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {el.label}
                      </a>
                    )}
                  </li>
                ))}
                {urls.map((el, index) => (
                  <li
                    className="inline leading-8 text-xl tablet:text-tiny"
                    key={index}
                  >
                    {el.url ? (
                      <Link
                        target="_blank"
                        className="text-white px-1 my-3 tablet:my-0"
                        to={el.url}
                      >
                        {el.label}
                      </Link>
                    ) : (
                      <a
                        className="text-white px-1 my-3 tablet:my-0"
                        href={el.file[0].url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {el.label}
                      </a>
                    )}
                  </li>
                ))}
                <li className="inline leading-8 text-xl tablet:hidden">
                  <Link
                    target="_blank"
                    className="text-white px-1 my-3 tablet:my-0"
                    to="/"
                  >
                    <img
                      style={{ top: '-1px' }}
                      className="relative inline"
                      src="/france.png"
                      alt="french flag"
                    />
                  </Link>
                </li>
              </ul>
              <div
                onClick={() => setMenuOpen(!menuOpen)}
                className="tablet:hidden mx-auto text-center py-1"
              >
                {menuOpen ? (
                  <img className="mx-auto" src="/cross.png"></img>
                ) : (
                  'MENU'
                )}
              </div>
            </nav>
          </DetectOutside>
        )
      }}
    />
  )
}

const query = graphql`
  query menuQueryEn {
    allStrapiNavigationDuFooter(filter: { locale: { eq: "en" } }) {
      nodes {
        url
      }
    }
    allStrapiMenu(filter: { locale: { eq: "en" } }) {
      nodes {
        url
      }
    }
  }
`

export default NavbarFooterEn
