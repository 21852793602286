import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Image from '~/components/image'
import Socials from '~/components/socials'
import NavbarMain from '~/components/navbarMain'
import NavbarMainEn from '~/components/navbarMainEn'

const Header = ({ isEn = false, socials, isAbsolute }) => {
  return (
    <StaticQuery
      query={query}
      render={({ allStrapiHeader }) => {
        const logo = allStrapiHeader.nodes[0].logo
        return (
          <header
            className={`${
              isAbsolute ? 'w-full absolute top-0 bottom-0' : ''
            } z-10 bg-white text-center`}
          >
            <Link className="mx-2 text-lg w-40 mb-4" to={isEn ? '/en' : '/'}>
              <Image
                alt="Logo"
                className="rounded-t-md border-gray-200 logo"
                image={logo}
              />
            </Link>
            <Socials socials={socials} />
            {isEn ? <NavbarMainEn /> : <NavbarMain />}
          </header>
        )
      }}
    />
  )
}

const query = graphql`
  query headerQuery {
    allStrapiHeader {
      nodes {
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, height: 128)
            }
          }
        }
      }
    }
  }
`

Header.propTypes = {
  siteName: PropTypes.string,
}

Header.defaultProps = {
  siteName: ``,
}

export default Header
