import React from 'react'

const Socials = ({ socials }) => {
  return (
    <ul className="hidden tablet:flex absolute" style={{ top: 0, right: 0 }}>
      {socials.Email && (
        <a href={socials.Email} target="_blank" rel="noreferrer">
          <img
            src="/mail.png"
            alt="Email logo"
            width={24}
            className="opacity-80 m-1"
          />
        </a>
      )}
      {socials.Facebook && (
        <a href={socials.Facebook} target="_blank" rel="noreferrer">
          <img
            src="/facebook.png"
            alt="Facebook logo"
            width={24}
            className="opacity-80 m-1"
          />
        </a>
      )}
      {socials.Instagram && (
        <a href={socials.Instagram} target="_blank" rel="noreferrer">
          <img
            src="/instagram.png"
            alt="Instagram logo"
            width={24}
            className="opacity-80 m-1"
          />
        </a>
      )}
    </ul>
  )
}

export default Socials
