import React from 'react'

const Button = ({ label, type, isDisabled }) => {
  return (
    <button
      disabled={isDisabled}
      className={`block rounded mx-auto mb-3 px-3 py-1 bg-blue text-white ${
        isDisabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      type={type}
    >
      {label}
    </button>
  )
}

export default Button
